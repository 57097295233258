import {GridColDef} from '@mui/x-data-grid'

import {equalsOperator, matchString} from '../../genericComponents/grid/gridFilterOperators'
import {
    formatEmail,
    getFormattedDate,
    getRequesterEmail,
    getRoleLabel, updateRole
} from '../../genericComponents/factoryUser/FactoryUserFormatter'
import { useDispatch } from 'react-redux'
import {SortDates} from '../../genericComponents/utils/DateService'
import DaTXDataGrid from '../../genericComponents/grid/DaTXDataGrid'
import React, {useState} from 'react'
import {useGetActiveFactoryUsersQuery, useUpdateFactoryUserMutation} from '../rtkFactoryApi'
import {FactoryUser} from '../CreateFactoryUser/model/FactoryUser'
import EditRoleModal from './EditRoleModal'
import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import './ActiveFactoryUsersTable.scss'
import {UserRoleEnum} from '../CreateFactoryUser/model/UserRoleEnum'
import {setSnackbarMessage} from '../../util/commonSlice'

const ActiveFactoryUsersTable = () => {
    const dispatch = useDispatch()
    const [openRoleDialog, setOpenRoleDialog] = useState(false)
    const [userRoleToUpdate, setUserRoleToUpdate] = useState<FactoryUser>()

    const {data: factoryUsers = [], isLoading} = useGetActiveFactoryUsersQuery()
    const [updateFactoryUser, updateFactoryUserResult] = useUpdateFactoryUserMutation()

    const submitUpdatedFactoryUser = (userId: string , newRole: UserRoleEnum) =>    {
        const selectedUser = factoryUsers.find(user => user.id === userId)
        if(!selectedUser) {
            dispatch(setSnackbarMessage('User not found'))
            return
        }
            const factoryUserUpdated: FactoryUser = {
                    ...selectedUser,
                    id: userId,
                    roles: [newRole]
            }
        updateFactoryUser(factoryUserUpdated).unwrap()
            .then(()=> dispatch(setSnackbarMessage('User role has been updated')))
            .catch((error)=> dispatch(setSnackbarMessage(error)))
    }

    const onEditRoleClick = (params: GridCellParams) =>{
        setUserRoleToUpdate(factoryUsers?.find(user => user.id === params.id))
        setOpenRoleDialog(true)
    }
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'User ID',
            flex: 1,
            minWidth: 120,
            sortable: true,
            renderCell: formatEmail,
            valueGetter: getRequesterEmail,
            filterOperators: [matchString]
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            minWidth: 150,
            sortable: true,
            valueGetter: getRoleLabel,
            renderCell: (params) => updateRole(params, onEditRoleClick) ,
            filterOperators: [equalsOperator]
        },
        {
            field: 'updatedDate',
            headerName: 'Last Modified',
            flex: 1,
            minWidth: 150,
            sortable: true,
            filterable: false,
            valueGetter: getFormattedDate,
            sortComparator: (v1, v2) => SortDates(v1, v2),

        }
    ]
    return (
        <>
            <h1 className="page-title-factory-user">User Management</h1>
            <div className="factory-users-grid-container">
                <DaTXDataGrid
                    rowHeight={42}
                    isLoading={isLoading}
                    emptyRowMessage="No factory users found"
                    columns={columns}
                    rows={factoryUsers}
                    getRowId={(factoryUser: FactoryUser) => factoryUser?.id}
                    children={null}
                />
                <EditRoleModal
                    isOpen = {openRoleDialog}
                    onSubmit = {submitUpdatedFactoryUser}
                    isSubmitLoading={updateFactoryUserResult.isLoading}
                    onClose = {()=> setOpenRoleDialog(false)}
                    selectedFactoryUser={userRoleToUpdate}
                />
            </div>
        </>
    )}

export default ActiveFactoryUsersTable
