import React from 'react'
import {getUserTypeDisplayName, UserRoleEnum} from '../CreateFactoryUser/model/UserRoleEnum'
import {FactoryUser} from '../CreateFactoryUser/model/FactoryUser'
import {useFormik} from 'formik'
import {object, string} from 'yup'
import {IdType} from '../../util/models/IdType'
import {MenuItem, Select} from '@mui/material'
import CustomModal from '../../genericComponents/customModal/CustomModal'
import {LoadingButton} from '@mui/lab'

const EditRoleModal = ({
                           isOpen,
                           onSubmit,
                           isSubmitLoading,
                           onClose,
                           selectedFactoryUser,
                       }: {
    isOpen: boolean;
    onSubmit: (userId: IdType, newRole: UserRoleEnum) => void;
    isSubmitLoading: boolean;
    onClose: () => void;
    selectedFactoryUser?: FactoryUser;
}) => {

    const validationSchema = object({
        role: string()
            .required('Role is required')
            .oneOf(Object.values(UserRoleEnum), 'Invalid role'),
    })

    const formik = useFormik({
        // order of variables does not matter, both fields derive independently of selectedFactoryUser
        initialValues: {
            role: selectedFactoryUser?.roles[0],
            id: selectedFactoryUser?.id,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.id) {
                onSubmit(values.id, values.role as UserRoleEnum)
                onClose()
            }
        }
    })

    if (!isOpen) return null
    return (
        <CustomModal
            handleClose={onClose}
            title={<span>Edit Role</span>}
            body={
                <form onSubmit={formik.handleSubmit}>
                    <div className="edit-role-details-modal">
                        <div className="role-details">
                            <label className="detail-name">Email:</label>
                            <label className="detail-value">
                                {selectedFactoryUser?.email}
                            </label>
                        </div>

                        <div className="role-details">
                            <label className="detail-name">Current Role:</label>
                            <label className="detail-value" data-testid="current-role">
                                {selectedFactoryUser?.roles[0] ?
                                    getUserTypeDisplayName[UserRoleEnum[selectedFactoryUser.roles[0] as keyof typeof UserRoleEnum]]
                                    : '-'}
                            </label>
                        </div>

                        <div className="role-details">
                            <label className="detail-name">
                                Change Role To: <label className="asterisk">*</label>
                            </label>
                            <Select
                                id="role"
                                value={formik.values.role}
                                onChange={(event) => formik.setFieldValue('role', event.target.value)}
                                className="role-dropdown"
                                size="small"
                                required
                                error={Boolean(formik.errors.role)}
                                displayEmpty
                                data-testid="role-dropdown"
                                renderValue={(selected) =>
                                    selected ? getUserTypeDisplayName[selected as UserRoleEnum] : 'Select Role'
                                }
                            >
                                {Object.values(UserRoleEnum).map((role) => (
                                    <MenuItem key={role} value={role} data-testid={`role-dropdown-option-${role}`}>
                                        {getUserTypeDisplayName[role]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </form>
            }
            actions={
                <ModalActionRow
                    formSubmission={formik.handleSubmit}
                    isSubmitLoading={isSubmitLoading}
                    shouldUpdateButtonDisabled={!formik.isValid || !formik.dirty}
                />
            }
        />
    )
}

const ModalActionRow = ({
                            formSubmission,
                            isSubmitLoading,
                            shouldUpdateButtonDisabled,
                        }: {
    formSubmission: () => void;
    isSubmitLoading: boolean;
    shouldUpdateButtonDisabled: boolean;
}) => {
    return (
        <div className="update-button-container">
            <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                className="update-button"
                data-testid="update-button"
                onClick={formSubmission}
                loading={isSubmitLoading}
                disabled={shouldUpdateButtonDisabled}
            >
                Update
            </LoadingButton>
        </div>
    )
}

export default EditRoleModal
