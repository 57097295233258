import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {FACTORY_API_ENDPOINT} from '../util/constants/backendUrls'
import {FactoryUser} from './CreateFactoryUser/model/FactoryUser'

export const FACTORY_TAG = 'Factory'

export const factoryApi = createApi({
    reducerPath: 'factoryApi',
    keepUnusedDataFor: 0,
    tagTypes: [FACTORY_TAG],
    baseQuery: axiosBaseQuery({baseUrl: `${FACTORY_API_ENDPOINT}`}),
    endpoints: (builder) => ({
        createFactoryUser: builder.mutation<FactoryUser, FactoryUser>({
            query: (factoryUser) => ({
                url: '/datx/users',
                method: 'POST',
                data: factoryUser
            }),
            transformResponse: (response: FactoryUser) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when creating user',
            invalidatesTags: [FACTORY_TAG]
        }),
        updateFactoryUser: builder.mutation<FactoryUser, FactoryUser>({
            query: (factoryUser) => ({
                url: '/datx/users/' + factoryUser.id,
                method: 'PUT',
                data: factoryUser.roles
            }),
            transformResponse: (response: FactoryUser) => response,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating user role',
            invalidatesTags: [FACTORY_TAG]
        }),
        getActiveFactoryUsers: builder.query<FactoryUser[],  void>({
            query: () => ({
                url: '/datx/users/all',
                method: 'GET'
            }),
            transformResponse: (response:any) => response.data,
            transformErrorResponse: (response: any) => response?.data?.errorMessage || 'Something went wrong when updating the display sets',
            providesTags: [FACTORY_TAG]
        })
    })
})

export const {useCreateFactoryUserMutation, useUpdateFactoryUserMutation, useGetActiveFactoryUsersQuery} = factoryApi